<template>
  <div class="register-container">
    <div v-if="step === 1">
      <div class="register-title">
        网上农博店铺账号注册
      </div>
      <div class="register-tip">
        欢迎入驻
      </div>
      <el-form ref="form" :model="form" :rules="rules">
        <el-form-item
          label="手机号"
          prop="mobile"
        >
          <el-input v-model="form.mobile" placeholder="请输入手机号" ></el-input>
        </el-form-item>
        <el-form-item
          label="验证码"
          prop="code"
        >
          <div :class="[this.countDown !== 0 ? 'get-coding' : '']">
            <el-input v-model="form.code" placeholder="请输入验证码">
              <template slot="append">
                <div class="get-code-container" @click="getCode">
                  {{ codeText }}
                </div>
              </template>
            </el-input>
          </div>
        </el-form-item>
        <el-form-item
          label=""
          prop="agreement"
        >
          <el-checkbox v-model="form.agreement">
            我已仔细阅读并同意
            <span class="agreement" @click.prevent.stop="openAgreement">《商家入驻协议》</span>
          </el-checkbox>
        </el-form-item>
      </el-form>
      <el-dialog
        :visible="dialogVisible"
        top="5vh"
        @close="dialogVisible = false"
      >
        <protocol />
      </el-dialog>
      <div class="open-shop-container">
        <el-button style="width: 350px;" type="primary" @click="submit">申请开店</el-button>
      </div>
    </div>
    <verify
        @success="verifySuccess"
        mode="pop"
        captchaType="blockPuzzle"
        :imgSize="{ width: '300px', height: '150px' }"
        ref="verify"
        explain="向右滑动完成验证"
        :mobile="form.mobile"
        scene="SUPPLIER_REGISTER_NB"
    ></verify>
  </div>
</template>
<script>
  import protocol from './protocol.vue'
  import verify from "@/components/verifition/Verify";
  import * as validate from '@/utils/validate'
  import info from './info.vue'
  const timer = null;
  export default {
    components: {
      protocol,
      verify,
      info
    },
    data() {
      return {
        step: 1,
        form: {
          mobile: '',
          code: '',
          agreement: []
        },
        infoData: undefined,
        dialogVisible: false,
        countDown: 0
      }
    },
    computed: {
      codeText() {
        return this.countDown !== 0  ? `${this.countDown}秒` : '获取验证码'
      },
      rules() {
        const rules = {
          mobile: [
            validate.mobile,
          ],
          code: [
            { required: true, message: '请正确输入验证码', trigger: 'change'}
          ],
          agreement: [
            { 
              type: 'array', required: true, message: '请确认商家入驻协议后', trigger: 'change'
            }
          ]
        }

        return rules
      }
    },
    methods: {
      verifySuccess() {
        // params 返回的二次验证参数, 和登录参数一起回传给登录接口，方便后台进行二次验证
        this.$message({
          message: '验证码已发送',
          type: 'success'
        });
        this.countDown = 60
        this.$nextTick(()=>{
          this.$refs.form.clearValidate()
        })
        const timer = setInterval(()=>{
          this.countDown--
          if (this.countDown === 0) {
            clearInterval(timer)
          }
        }, 1000)
      },
      getCode() {
        if (this.countDown > 0) {
          return this.$message({
            message: '请稍后再获取验证码',
            type: 'warning'
          });
        }
        this.$refs.form.validateField('mobile', (valid)=>{
          if (!valid) {
            this.$refs.verify.show();
          }
        })
      },
      openAgreement() {
        this.dialogVisible = true
      },
      submit() {
        this.$refs.form.validate((valid)=>{
          if (valid) {
            this.$axios.post(this.$api.settle.register, {
              mobile: this.form.mobile,
              code: this.form.code
            }).then( r => {
              if (r.code === 0) {
                this.$router.push({
                  path: '/shopSettle/info',
                  query: {
                    id: r.data.supplierId,
                  }
                })
              }
            })
          }
        })
      }
    },
    destroyed() {
      clearInterval(timer)
    }
  }
</script>
<style lang="scss" scoped>
  .register-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    .register-title {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 20px;
    }
    .register-tip {
      margin-bottom: 30px;
    }
    .get-code-container {
      min-width: 110px;
      line-height: 38px;
      text-align: center;
      padding: 0 20px;
      color: #fff;
      cursor: pointer;
    }
    .get-coding {
      .get-code-container {
        color: initial;
      }
    }
    .get-coding {
      ::v-deep {
        .el-input-group__append {
          background-color: inherit;
        }
      }
    }
    ::v-deep {
      .el-input-group__append {
        padding: 0;
        background-color: #409EFF
      }
    }
    .agreement {
      color: #409EFF
    }
    .open-shop-container {
      width: 100%;
      padding-top: 20px;
      display: flex;
      justify-content: center;
    }
  }

</style>
